:root {
  --main-color:rgb(33, 19, 19);
  --secondary-color:#ac9a6e;
  --third-color: #ecbc44;
}

body{
  color: rgb(33, 19, 19);
}


.divsectionheaders h1{
  font-weight: normal;
}

.starwhite {
  padding: 0px;
  background:transparent;
  border: none;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 16px;


}

.imgstarwhite{
  width: 18px;
  vertical-align: bottom;
}


p{
  margin: 0px;
  padding: 0px;
}

.admindiv{
  min-height: 75vh;
  padding-bottom: 20px;
}


.team{
  background-color: var(--secondary-color);
  color: white;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;

}

.teamdiv{
  padding-bottom: 25px;
}


.headerimg{
 width:100%;
 padding-top: 10px;
 padding-bottom: 10px;
 display: inline-flex;
 justify-content: space-between;
 align-items: center;
  background-color:rgb(17, 22, 26)
}

.flexdiv{
  width:120px;
  display: inline;
  
}
.flexdiv2{
  width:400px;
  display: inline;
  
}
.logo {
  width: 100%;


}


.balance {
  width: 100%;

 
}



.navigationlinks{
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  
 
}



.navul{
  
  list-style: none;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  border-top: solid var(--secondary-color);
  border-bottom:solid var(--secondary-color);
}

.bimage{
  position:fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  max-width:100%;
  height:auto;
  z-index:0;
}
.navulist {
  list-style: none;
  padding: 20px;
  display: flex;
  justify-content: center;
  color: var(--secondary-color);
}

.navbullets {
  font-style: oblique;
  font-size: 25px;
}

.navbullets:before {
  content: "";  
  display: inline-block;
  vertical-align: middle;
  background: url('./Components/img/bullets.png') no-repeat center center;
  background-size: cover;
  width: 30px;  /* Set width and height to your desired size */
  height: 30px;
  margin-right: 5px;
}


.navlinks{
  
  position: fixed;
  top: 2px;
  right: 2px;
  padding: 0px;
  margin: 0px;
  width: 60%;
  background: transparent;
}




.nvul{
  display: none;

}


.nbullets {
  font-style: oblique;
  font-size: 12px;
  padding: 2px;
  
}

.nbullets:before {
  content: "";  
  display: inline-block;
  vertical-align: middle;
  background: url('./Components/img/bullets.png') no-repeat center center;
  background-size: cover;
  width: 10px;  /* Set width and height to your desired size */
  height: 10px;
  margin-right: 5px;
}


.sectionheaders{
  display: flex;
  justify-content: center;
  padding: 25px;
  margin: 0px;
  
}
.sectionheaders img{
width: 100%;
  
}

.psubject{
  padding: 0px
}
.background-image-ul {
  background-image: url('./Components/img/background.jpg'); /* Replace with your image path */
  background-size: 100%; /* Cover the entire ul area */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;

   /* Prevent the image from repeating */
}



.titlediv{
  position: relative;
  padding-bottom: 30px;
}


.titlediv::after {
  content: "";
  position: absolute;
  left: 0;
  transform: translatex(12%);
  bottom: 0; /* Position it at the bottom */
  width: 80%; /* Adjust this to control the border length */
  height: 3px; /* This controls the thickness of the border */
  background-color: var(--main-color) /* Replace with your desired border color */
}

.titledivlast{
  position: relative;
  padding-bottom: 30px;
}

.titledivlast::after {
  content: "";
  position: absolute;
  left: 0;
  transform: translatex(9%);
  bottom: 0; /* Position it at the bottom */
  width: 84%; /* Adjust this to control the border length */
  height: 3px; /* This controls the thickness of the border */
  background-color: var(--main-color) /* Replace with your desired border color */
}



.divsectionheaders{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

 
  
}
.divinput{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
 
  
}

.nodisplay-more{
  display: none;
}


.read-more-less {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  padding: 0px;
  margin: 0px;
  border: none;
  font-size: 25px;
}
.divsection{
margin: 25px 0px;
  
}

.divuldocs{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
 
 
}

.titledivul{
  z-index: -1;
  background-color: var(--secondary-color)
}


.divuldocs ul {
  list-style: none;
  margin: 0;
  padding: 0px 5px;
}
.divuldocs h1 {
  -webkit-text-stroke: 0.3px rgb(22, 22, 21);  /* Stroke width and color */
  color: rgb(69, 46, 15)
}
.divuldocs li {
  border-radius: 9px;
  background-color: rgba(255, 255, 255, 0.588); /* 50% transparent white */
}






.acformdiv{
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acform{
  background-color: var(--secondary-color);
  border-radius: 25px;
  width: 80vh;
  padding: 0px 0px 25px 0px;
}

.inputdiv{
  display:block;
  padding: 10px;
  margin-top: 10px;
}
.aclabel{
  color: white;
  display: block;
  font-size: 22px;
}
.inputdiv input{
  display: block;
  width: 97%;
  height: 25px;

}
.textareaFullWidth {
  width: 97%;
}

.acform button{
  margin-top: 10px;
  
}

.sentbutton{
  font-size: 25px;
}

.buttdiv{
  display: flex;
  justify-content: center;
  align-items: center;
}




.footer{
  background-color: black;
  color: aliceblue;
 
}
.flexdivfooter{
  display: flex;
  justify-content: center;
  align-items:flex-start;
  background-color: black;
  color: aliceblue;
  flex-wrap: wrap;
}
.divfooter{
margin-left: 15px;
margin-right: 15px;
}
.divfooter h4{
margin: 15px 0px 0px
}
.divfooter h5{
margin: 0px
}

.cfootertitle{
  text-align: center;
}

.bsession{
  margin: 22.72px 0px;
  padding: 0;
}

.createdbylink{
  display: flex;
  justify-content: center;
  align-items:flex-start;
}

.createdbylink h6{
  padding: 10px;
  margin: 0px;
}

.ctitle{
  text-align: center;
  border: solid 2px rgb(236, 240, 242); 
  border-radius: 9px;
  
}
.ftitle{
  text-align: center;
  border: solid 2px rgb(236, 240, 242); 
  border-radius: 9px;
  
}

body {
  margin:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menuswitch{
  display: none;
}

.bresetpass{
  display: block;
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
}

.sessionstart{
  margin: 10px 10px;
  margin-left: 25%;

}
.sessionclose{
  margin: 10px 0px;
  margin-left: 36%;

}
.passreset{
  margin: 10px 0px;
  margin-left: 19%;

}
.createuser{
  border: none;
  background: none;
  margin: 10px 0px;
  text-decoration: underline;

}


.headersadmin{
  display: flex;
  justify-content: center;
font-size: 25px;
align-items: center;
font-weight: bolder;
border: solid black;
}

.appsdiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.appscont{
  margin: 10px;
  padding: 5px;
  border: solid black;
}

.appscont h4{
  padding: 0px;
  margin: 2px;
}
.appscont h5{
  padding: 0px;
  margin: 2px;
}

.timeapp{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.formdatescreate{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
}

.divtimesel{
  margin: 5px;
}
.schedulebutton{
  display: flex;
  justify-content: center;
  align-items: center;
}

.notedate{
  width:200px
}

.formdatescreate label{
  font-weight: bolder;
}

.picker{
  z-index: 9999 !important; /* use a high value to ensure it's above other elements */
}

.centerdiv{
  display: flex;
  justify-content: center;
  align-items: center;
}



.homemodal {
  
  width: '200px';
 
  height: '220px';
  margin: 'auto';
  background-Color: 'white';
  display: 'flex';
  flex-direction: 'column';
  justify-Content: 'center';
  align-Items: 'center';
  padding: '0px';
  border-Radius: '5px'
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  width: 200px;
  height: 320px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-Direction: column;
  justify-Content: center;
  align-Items: center;
  padding: 0px;
  border-Radius: 5px;
}



.teamcurr {
 
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  
}
.teamdivtext p {
  text-align: justify;
  padding: 10px;
font-size: 30px;
  
}



.teamdivimg{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.teamdivtext{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.imgteam{
 
max-width: 220px;
}

.backbutton{
  height: auto;
  border-radius: 10px;
  font-size: 25px;
}

.flexdivall{
  padding: 5px 0px;
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.flexdivops {
  padding: 20px;
  margin: auto;
  width: fit-content;
  background-color: #ac9a6e;
  border-radius: 20px;
}
.flexdivops2 {
  
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  
}

.opdiv{
  padding: 20px;
  margin: 10px;
  background-color: #ac9a6e;
  border-radius: 20px;
}

.opdiv p {
  max-width: 300px;
  word-wrap: break-word;
}

.flexdivops p{
  max-width: 300px;
  word-break: break-word;
  
}
.flexdivops2 p{
  max-width: 300px;
  word-break: break-word;
}

.subopdiv{
  display:flex;
  align-items: center;
  justify-content: center;
  
}

.subopdiv h4{
  width: 350px;
  padding: 0px;
  margin: 0px;
}

.subopdiv input{
  width: 350px;
  height: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.subopdiv textarea{
  width: 350px;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.subopdiv div{
  width: 350px;
  margin-bottom: 15px;
  
}

.visitas{
  display:flex;
  align-items: center;
  justify-content: center;

}

.visitas h3{
  background-color: cadetblue;
  padding: 5px 10px;
  border-radius: 10px;
}






@media screen and (max-width: 890px) {
  .teamdivtext p {
  
  font-size: 25px;
    
  }

  .imgteam{
 
    max-width: 150px;
    }

 
}
@media screen and (max-width: 815px) {
 
  .navbullets {
    font-style: oblique;
    font-size: 25px;
  }
  
  .navbullets:before {
    content: "";  
    display: inline-block;
    vertical-align: middle;
    background: url('./Components/img/bullets.png') no-repeat center center;
    background-size: cover;
    width: 18px;  /* Set width and height to your desired size */
    height: 18px;
    margin-right: 3px;
  }
 
}
@media screen and (max-width: 750px) {
 
  .navbullets {
    font-style: oblique;
    font-size: 22px;
  }
  
  .navbullets:before {
    content: "";  
    display: inline-block;
    vertical-align: middle;
    background: url('./Components/img/bullets.png') no-repeat center center;
    background-size: cover;
    width: 15px;  /* Set width and height to your desired size */
    height: 15px;
    margin-right: 2px;
  }
 
}
@media screen and (max-width: 670px) {
 
  .navbullets {
    font-style: oblique;
    font-size: 18px;
  }
  
  .navbullets:before {
    content: "";  
    display: inline-block;
    vertical-align: middle;
    background: url('./Components/img/bullets.png') no-repeat center center;
    background-size: cover;
    width: 10px;  /* Set width and height to your desired size */
    height: 10px;
    margin-right: 2px;
  }
 
}
@media screen and (max-width: 500px) {


    .backbutton{
      
      border-radius: 5px;
      font-size: 21px;
    }

    .headersadmin{
      font-size: 20px;
    }
}
@media screen and (max-width: 360px) {

  .opdiv p {
    width: 100%;
   
  }

  .flexdivops p{
    width: 100%;
    
  }
  .flexdivops2 p{
    width: 100%;
  }

}
@media screen and (max-width: 300px) {


    .backbutton{
      
      border-radius: 3px;
      font-size: 20px;
    }

    .teamdivtext p {
  
      font-size: 15px;
        
      }

    

}
@media screen and (max-width: 285px) {


    .backbutton{
      
      border-radius: 3px;
      font-size: 15px;
    }


    

}
@media screen and (max-width: 247px) {


    .backbutton{
      
      border-radius: 3px;
      font-size: 12px;
    }


    

}

@media screen and (max-width: 220px) {


    .imgteam{
      
     width:100%
    }


    

}
@media screen and (max-width: 650px) {
  .flexdiv2{
    width:300px
  }
  .flexdiv{
      width:90px
  }
}
@media screen and (max-width: 600px) {
  .flexdiv2{
    width:250px
  }
  .flexdiv{
      width:60px
  }
}
@media screen and (max-width: 420px) {
  .flexdiv2{
    width:250px
  }

}
@media screen and (max-width: 390px) {
  .flexdiv2{
    width:220px
  }
}
@media screen and (max-width: 370px) {
  .flexdiv2{
    width:210px
  }
  .flexdiv{
    width:50px
}
}
@media screen and (max-width: 326px) {
  .flexdiv2{
    width:200px
  }
  .flexdiv{
    width:45px
}

.headersadmin{
  font-size: 16px;
}
}
@media screen and (max-width: 300px) {
  .flexdiv2{
    width:190px
  }
  .flexdiv{
      width:40px
  }
}
@media screen and (max-width: 284px) {
  .flexdiv2{
    width:180px
  }
  .flexdiv{
      width:38px
  }

  .headerimg{
    
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 274px) {
  .flexdiv2{
    width:170px
  }
  .flexdiv{
      width:35px
  }

  .headerimg{
    
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 248px) {
  .flexdiv2{
    width:100%
  }
  .flexdiv{
      display: none;
  }

  .headerimg{
    
    padding-top: 12px;
   
  }
}
@media screen and (max-width: 500px){
  
}

@media screen and (max-width: 500px) {


  
  .read-more-less {
    font-size: 20px;
  }

  .menuswitch{
    display: flex;
    width: 100%;
   
  justify-content: flex-end;
    padding: 0px;
    font-size: 15px;
    
    background-color: transparent;
   
   

    color: black;
    

    

    
  }

  .menuswitch h1{
    background-color: white;
    margin: 5px 10px;
    padding: 0px;
    font-size: 15px;
    
    text-align: center;
    transform: scaleX(1.7)
  }




  .nvul{
    display: none;
 
  }

  .navlinks:hover .nvul {
    display: flex;
    font-size: large;
    list-style: none;
    padding: 0px;
    margin: 0px;
   background: white;
    justify-content: center;
    
    flex-direction: column;

  }
  .sentbutton{
    font-size: 20px;
  }

 

  .footer h6{
      margin: 0px;
      padding: 10px 0px;
  }

  .divfooter{
    margin-left: 10px;
    margin-right: 10px;
    }
    .divfooter h4{
    margin: 10px 0px 0px;
    font-size: 15px;
    }
    .divfooter h5{
    margin: 0px;
    font-size: 12px;
    }
    .footerfollows h3{
   
    font-size: 18px;
    }

    .divfooter h3{
      font-size: 18px;
    }
    
    .bsession{
      margin: 22.72px 0px;
      padding: 0;
    }
  .imageheader{
    width: 300px;
  }

  .navbullets{
    display: none;
  }
  .navul{
    display: none;
  }
  .navigationlinks{
    display: none;
  }

 
  h1{
    font-size: 25px;
    padding: 0px;
  }
  .aclabel{
    font-size: 17px
  }

  .acform{
    width: 100%;
    padding: 15px 10px;
  }

  .inputdiv{
    margin-top: 6px;
  }
  .inputdiv input{
    width: 100%;
    height: 18px;
  
  }

  .acform button{
    margin-top: 5px;
    
  }

}

@media screen and (max-width: 340px) {

  .read-more-less {
    font-size: 15px;
  }
  .createdbylink h6{
    padding: 2px;
    margin: 0px;
  }

  
  .sentbutton{
    font-size: 17px;
  }

  .bsession{
    margin: 22.72px 10px;
    padding: 0px ;
  }

  .flexdivfooter{
    padding: 0px 10px;
  }

  .flexdivfooter h6{
      margin: 0px;
      padding: 10px 0px;
  }

  .divfooter{
    margin-left: 10px;
    margin-right: 10px;
    }
    .divfooter h4{
    margin: 10px 0px 0px;
    font-size: 15px;
    }
    .divfooter h5{
    margin: 0px;
    font-size: 12px;
    }
    .footerfollows h3{
   
    font-size: 18px;
    }

    .divfooter h3{
      font-size: 18px;
    }

    select{
      width: 250px;
    }
    
    .bsession{
      margin: 22.72px 0px;
      padding: 0;
    }
  .imageheader{
    width: 250px;
  }

  .navbullets{
    display: none;
  }
  .navul{
    display: none;
  }
  .navigationlinks{
    display: none;
  }

  

  h1{
    font-size: 20px;
    padding: 0px;
  }
  .aclabel{
    font-size: 13px
  }

  .acform{
    width: 100%;
    padding: 15px 10px;
  }

  .inputdiv{
    margin-top: 6px;
  }
  .inputdiv input{
    width: 100%;
    height: 18px;
  
  }

  .acform button{
    margin-top: 5px;
    
  }

  li{
    padding: 0px;
    margin: 0px;
  }

}
@media screen and (max-width: 280px) {
    h1{
      font-size: 17px;
    }


    select{
      width: 200px;
      font-size: 11px;
    }
    
    .imageheader{
      width: 200px;
    }
 

}

@media screen and (max-width: 225px) {

  .bimage{
    max-width:95%;
   
  }

  select{
    width: 180px;
    font-size: 10px;
  }

}
@media screen and (max-width: 217px) {

  .bimage{
    display: none;
   
  }

}

@media screen and (max-width: 850px) {
  .divuldocs li {
    border-radius: 9px;
    background-color: rgba(255, 255, 255, 0.356); /* 50% transparent white */
  }
 }



@media screen and (max-width: 590px) {
  .background-image-ul {
    background-image: url('./Components/img/background.jpg'); /* Replace with your image path */
    background-size: 130%; /* Cover the entire ul area */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;
  
     /* Prevent the image from repeating */
  }
 }
@media screen and (max-width: 410px) {
  .background-image-ul {
    background-image: url('./Components/img/background.jpg'); /* Replace with your image path */
    background-size: 170%; /* Cover the entire ul area */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;
  
     /* Prevent the image from repeating */
  }
 }
@media screen and (max-width: 240px) {
  .background-image-ul {
    background-image: url('./Components/img/background.jpg'); /* Replace with your image path */
    background-size: 210%; /* Cover the entire ul area */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;
  
     /* Prevent the image from repeating */
  }
 }
